import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonContent } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';
import { DisplayModalComponent } from '../utils/display-modal/display-modal.component'

import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;

  resources
  articles
  webinars
  trainings
  videos = []
  selectedCountry
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore, private firebaseAuth: AngularFireAuth, public modalController: ModalController, 
    private authService: AuthService, private viewportScroller: ViewportScroller) {
    
  }
  


  ngOnInit() {
    this.getArticles() 
    this.getVideos()
    this.getTrainings()
    this.getWebinars()
  }

  getArticles(){
    this.firestore.collection('resources/article/articles').snapshotChanges()
    .subscribe(
      (res)=>{
        this.articles = []
        res.forEach(
          r=>{
            let art = {}
            art['type'] = 'article-link'
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc['id']
            art['data'] = art['info']['date_time'].toDate()
            this.articles.push(art)
          }
        )
        this.getFullArticles()
        this.articles
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        // console.log('resources ', this.resources)
      }
    )
  }

  getFullArticles(){
    this.firestore.collection('resources/full-article/full-articles').snapshotChanges()
    .subscribe(
      (res)=>{
        res.forEach(
          r=>{
            let art = {}
            art['type'] = 'full-article'
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc['id']
            art['data'] = art['info']['date_time'].toDate()
            this.articles.push(art)
          }
        )
        this.articles
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        // console.log('resources ', this.resources)
      }
    )
  }

  getVideos(){
    this.firestore.collection('resources/video/videos').snapshotChanges()
    .subscribe(
      (res)=>{
        this.videos = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc['id']
            this.videos.push(art)
          }
        )
        // console.log('videos ', this.videos)
      }
    )
  }

  getWebinars(){
    this.firestore.collection('resources/webinar/webinars').snapshotChanges()
    .subscribe(
      (res)=>{
        this.webinars = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc['id']
            this.webinars.push(art)
          }
        )
        // console.log('webinars ', this.webinars)
      }
    )
  }

  getTrainings(){
    this.firestore.collection('resources/training/trainings').snapshotChanges()
    .subscribe(
      (res)=>{
        this.trainings = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc['id']
            this.trainings.push(art)
          }
        )
        // console.log('trainings ', this.trainings)
      }
    )
  }

  scroll(id){
    var el = document.getElementById(id);
    this.content.scrollToPoint(0, el.offsetTop - 72, 1000);
    // this.content.sc
  }

  async showArticle(article){
    // console.log('show article', article)
    let articleText = '<div class="news-title">' + article.info.title + '<div class="news-date">' 
                      + article.data.getDate() + '-' + article.data.getMonth() + '-' + article.data.getFullYear()
                      + '</div></div><br><br><br>'
    articleText += '<div class="article-body">' 
    articleText += article.info.longText
    articleText += '</div>'
    const modal = await this.modalController.create({
      component: DisplayModalComponent,
      componentProps: { 
        text: articleText
      }
    });
    return await modal.present();
  }

}
