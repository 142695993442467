import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
 
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {

  stripeMessage = ''

  elements: Elements;
  card: StripeElement;
  stripeTest: FormGroup;
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(private fb: FormBuilder, private stripeService: StripeService) { }
  
 

  ngOnInit() {
  //   // this.initStripe()
  //   this.stripeCheckoutLoader.createHandler({
  //     key: 'pk_test_2G6eiAqcABC7UffxjJHxu0PP00QQRN98KL',
  //     token: (token) => {
  //         // Do something with the token...
  //         // console.log('Payment successful!', token);
  //     }
  // }).then((handler: StripeCheckoutHandler) => {
  //     this.stripeCheckoutHandler = handler;
  // });




  }

public onClickBuy() {
    // this.stripeCheckoutHandler.open({
    //     amount: 3000,
    //     currency: 'EUR',
    // });
}

public onClickCancel() {
    // If the window has been opened, this is how you can close it:
    // this.stripeCheckoutHandler.close();
}

  // initStripe(){
  //   this.stripeTest = this.fb.group({
  //     name: ['', [Validators.required]]
  //   });
  //   this.stripeService.elements(this.elementsOptions)
  //     .subscribe(elements => {
  //       this.elements = elements;
  //       // Only mount the element the first time
  //       if (!this.card) {
  //         this.card = this.elements.create('card', {
  //           style: {
  //             base: {
  //               iconColor: '#abc612',
  //               color: '#000',
  //               lineHeight: '40px',
  //               fontWeight: 300,
  //               fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //               fontSize: '18px',
  //               '::placeholder': {
  //                 color: '#CFD7E0'
  //               }
  //             },
  //           },
  //           hidePostalCode: true
  //         });
  //         this.card.mount('#card-element');
  //       }
  //     });
  // }

  buy() {
  //   const name = this.stripeTest.get('name').value;
  //   this.stripeService
  //     .createToken(this.card, { name })
  //     .subscribe(result => {
  //       if (result.token) {
  //         // Use the token to create a charge or a customer
  //         // https://stripe.com/docs/charges
  //         // console.log('[BUY] result', result.token);
  //       } else if (result.error) {
  //         // Error creating the token
  //         this.stripeMessage = result.error.message
  //       }
  //     });
  }

}
