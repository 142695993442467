import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MainMenuComponent } from '../menus/main-menu/main-menu.component'

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
  users
  constructor(private firestore: AngularFirestore) { 

  }

  ngOnInit() {
    this.getUsersList()
  }

  getUsersList(){
    this.firestore.collection('users').snapshotChanges()
    .subscribe(
      (res)=>{
        // console.log('Users page] users  ', res)
        this.users = []
        res.forEach(
          r=>{
            this.users.push({key: r.payload.doc['id'], data: r.payload.doc.data()})
          }
        )
      }
    )
  }
}
