import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser'; 
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent,  HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,ValidationErrors } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { PaymentComponent } from '../payment/payment.component';

import {catchError} from 'rxjs/operators'

import {loadStripe} from '@stripe/stripe-js';
import { StripeCheckoutLoader, StripeCheckoutHandler } from 'ng-stripe-checkout';
// import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @Input() pachet: string;

  countryNames
  textCountries = 'countries'
  selectedCountries
  ammountToPay
  signupForm: FormGroup;
  data = {
    name: '',
    email: '',
    password: '',
    phone: '',
    body: '',
    subject: 'Dac6Guide - Register using bank transfer',
    companyName: '',
    companyRegNo: '',
    companyVatNo: '',
    companyBankAccount: '',
    companyBankName: '',
    countriesAccess : []
  }
  dropdownSettings = { 
    singleSelection: false, 
    text: 'Select Countries',
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    classes:"multiselect"
  };
  stripe = loadStripe('pk_live_yyZdFpk2m575sld77ytwIcXR00zEtpqayC', {
    apiVersion: '2020-03-02',
  });


  stripeCheckoutHandler: StripeCheckoutHandler

  constructor(private firestore: AngularFirestore, private http: HttpClient, public afAuth: AngularFireAuth, formBuilder: FormBuilder, 
    public modalController: ModalController, private stripeCheckoutLoader: StripeCheckoutLoader, private alertController: AlertController,
    private router: Router
    //  private stripeService: StripeService 
    ) {
    this.signupForm = formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      selectedCountries: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(5)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      phone: [null],
      companyName: [null],
      companyRegNo:[null],
      companyVatNo: [null],
      companyBankAccount:[null],
      companyBankName:[null]
  });

  }

  ngOnInit() {
    this.getCountries()
    this.initStripeCheckout()

  }

  initStripeCheckout(){
    this.stripeCheckoutLoader.createHandler({
      key: 'pk_live_yyZdFpk2m575sld77ytwIcXR00zEtpqayC',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto',
      token: (token) => {
          // Do something with the token...
          // // console.log('Payment successful!', token);
      },
      }).then((handler: StripeCheckoutHandler) => {
          this.stripeCheckoutHandler = handler;
      });
  }

  registerUser(){
    this.data['countriesAccess'] = []
      this.selectedCountries.forEach(
        (res)=>{
          this.data['countriesAccess'].push(res.itemName)
        }
      )

    // console.log('Register user: ', this.data)
    this.modalController.dismiss()


    // CREATE AUTHETIFICATION
    this.afAuth.createUserWithEmailAndPassword(this.data.email, this.data.password)
    .then((user) => {
      // this.afAuth.signOut()
      alert('User created succesfully!');
      // console.log('User created succesfully!', user)
        // CREATE USER IN DATABASE
        let dbUser = this.data;
        dbUser['active'] = true
        dbUser['userType'] = 'client'
        delete dbUser.password;
        delete dbUser.subject;
        delete dbUser.body;
        // console.log('[registerUser] create user', user.user.uid, dbUser);
        this.firestore.doc('users/' + user.user.uid).set(dbUser)
          .then(
            (res)=>{ 
              // console.log('Created database user ', res)
            // this.sendEmail(dbUser)
            }
          )
          .catch(
            (err)=>{ console.log('Error creating database user ', err)}
          )
    })
      .catch((error) => {
      alert(error);
      // console.log('Error' + error);
      });
  }

  selectCountries(event){

  }

  async registerWithCreditCardNative(){  
    if (this.selectedCountries.length === 28) {
      this.ammountToPay = 300000
    }
    else{
      this.ammountToPay = this.selectedCountries.length * 12500
    }

    this.stripeCheckoutHandler.open({
        name: 'Dac6 Guide',
        description: 'Your payment is securely processed via <a href="https://stripe.com/" target="_blank">Stripe</a>',
        amount: this.ammountToPay,
        currency: 'GBP',
        allowRememberMe: false,
        email: this.data.email
    })
    .then((token) => {
      // Do something with the token...
      // console.log('Token created. Sending to Stripe', token);
      this.http.post('https://payments.dac6guide.eu/pay.php', {amount: this.ammountToPay, currency_code: 'gbp', email: 'marius@dauphin.ro', token: token.id})
      .subscribe(
        (res) => {
          if (res['result'] === 'success') {
            // // console.log('Payment successfull', res)
            this.modalController.dismiss()
            this.registerUser()
            this.presentAlert('Payment successfull', 'Your payment was sucessfully processed.<br> Please wait a few moments while we set up you account.' )  
          }
          else{
            this.presentAlert('ERROR processing payment: ' ,  res['message'])
          }
          
        },
        (err) => {
          // console.log('ERROR processing payment: ' , err)
          
        }
      )
      
    })
    .catch((err) => {
      // Payment failed or was canceled by user...
      if (err !== 'stripe_closed') {
          throw err;
      }
    })


  }



  async registerWithCreditCard(){
    const modal = await this.modalController.create({
      component: PaymentComponent
    });
    return await modal.present()

  }

  registerWithBankTransfer(){

    // console.log('Data: ', this.data)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if(!this.data.name || !this.data.password || !this.data.email){
      alert('Please fill all the fields before sending!')
    }
    else{
      this.data.body = 'A new user requested an account on DAC6 Guide ' +  '<br> using Bank transfer<br>'
      this.data.body += 'Name: ' + this.data.name + '<br>'
      this.data.body += 'Email: ' + this.data.email + '<br>'
      this.data.body += 'Phone: ' + this.data.phone + '<br>'
      this.data.body += 'Password: ' + this.data.password + '<br>'
      this.data.body += 'Company Name: ' + this.data.companyName + '<br>'
      this.data.body += 'Company Reg No: ' + this.data.companyRegNo + '<br>'
      this.data.body += 'Company Vat No: ' + this.data.companyVatNo + '<br>'
      this.data.body += 'Company Bank Account: ' + this.data.companyBankAccount + '<br>'
      this.data.body += 'Company Bank Name: ' + this.data.companyBankName + '<br>'
      this.data.body += 'Countries: <br>'
      this.selectedCountries.forEach(
        (country, i)=>{
          // console.log('country', country)
          this.data.body += i + 1 + '. ' + country.itemName + '<br>'
        }
      )

            
      if(!this.data.name || !this.data.body || !this.data.email){
        alert('Please fill all the fields before sending!')
      }
      else{
        // // console.log('this.data.body', this.data.body)
        this.modalController.dismiss()
        this.http.post(
            'https://sendemail.dac6guide.eu/sendmail.php', this.data, httpOptions
        )
        .subscribe(
          res => {
            // console.log('Mail Result:' , res)
            this.data = {
              name: '',
              email: '',
              password: '',
              phone: '',
              body:'',
              subject: 'Dac6Guide - Register using bank transfer',
              companyName: '',
              companyRegNo: '',
              companyVatNo: '',
              companyBankAccount: '',
              companyBankName: '',
              countriesAccess : []
            }
            this.goToConfirm()
            // alert('Thank you for registering. We will get back to you in a very short time!')
            
          },
          err => {
            alert('Your message could not be sent! Please try again')
            // console.log('Mail Error:' , err)
            // this.showAlert('Error', 'User data wasnt completly saved! Please contact your system administrator!')
          }
        );
      }
    }
   
  }

  getCountries(){
    this.countryNames =[]
    this.firestore.collection('countries').snapshotChanges()
    .subscribe(
      (res)=>{
        res.sort((a, b) => a.payload.doc['id'].localeCompare(b.payload.doc['id']))
        let i = 0
        res.forEach(
          r=>{
            const countryId = r.payload.doc['id']
            let country = {name: countryId}
          i++
          this.countryNames.push({id: i, itemName: this.titleCase(countryId), originalName: countryId})
            }
        )
        if (this.pachet === 'full') {
          // console.log('getCountries FULL', this.countryNames)
          this.selectedCountries = this.countryNames
        }
      }
    )
  }

  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

  async presentAlert(title, content) {
    const alert = await this.alertController.create({
      header: title,
      message: content,
      buttons: ['OK']
    });

    await alert.present();
  }

  dismissModal(){
    this.modalController.dismiss()
  }

  goToConfirm(){
    this.modalController.dismiss()
    this.router.navigate(['banktransfer-sent']);
  }

}
