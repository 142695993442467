import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { IonReorderGroup } from '@ionic/angular';
import {map, take, filter} from 'rxjs/operators'
import { ModalController } from '@ionic/angular';
import {IonSlides} from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';
import { SafeHtmlPipe } from '../utils/safe-html-pipe/safe-html.pipe';

import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss'],
})
export class ComparisonComponent implements OnInit {
  @ViewChild(IonReorderGroup, { read: true, static: false }) reorderGroup: IonReorderGroup;
  // @ViewChild(IonSlides, {static: true}) slides: IonSlides;

  countries
  countryNames =[]
  fields
  selectedCountries =[]
  selectedSections = []
  selectedSubSections = {}
  selectedField
  fieldCounter
  modifiedFields =[]
  canSaveFields = false
  user
  userDetails
  userLoggedIn
  countriesToShow = 1
  countriesToCompareTo =[]
  appendices
  sectionNames = [
    'TAX AUTHORITY AND LEGISLATION',
    'LEGISLATIVE DETAILS',
    'MAIN BENEFIT TEST (MBT)',
    'LEGAL PROFESSIONAL PRIVILEGE (LPP)',
    'FORM OF REPORTING AND LANGUAGE',
    'TIMELINES',
    'PENALTIES',
    'ADDITIONAL GUIDANCE'
  ]
  slideOpts = {
    autoHeight: true,
    slidesPerView: 3,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
  };

  // slideOpts = {
  //   slidesPerView: 3,
  //   autoHeight: true
  // }


  constructor(private _firebaseAuth: AngularFireAuth, private firestore: AngularFirestore, public modalController: ModalController, private authService: AuthService) {
    this.authService.isLoggedIn().subscribe(
      (res)=>{
        this.userLoggedIn = res
      }
    )
    this.getAppendices()
   }

  ngOnInit() {
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.user = res;
            this.firestore.doc('users/' + this.user.uid).valueChanges().subscribe(
              (r)=>{
                this.userDetails = r
                // console.log('[Country Report] CurrentUser ', this.userDetails)
                this.userDetails.countriesAccess.forEach(
                  (country, i)=>{
                    this.userDetails.countriesAccess[i] = country.toLowerCase()
                  }
                )
                this.getCountries()
              }
            )
          }
          else {
            this.user = null;
          }
        }
    )
  }

  getCountries(){
    this.firestore.collection('countries').snapshotChanges()
    .subscribe(
      (res)=>{
        res.sort((a, b) => a.payload.doc['id'].localeCompare(b.payload.doc['id']))

        this.countries = {}
        res.forEach(
          r=>{
            const countryId = r.payload.doc['id']
            if ( this.userDetails.countriesAccess.includes(countryId.toLowerCase()) ) {
              let country = {name: countryId}
              // Get SECTIONS
              this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
              .subscribe(
                (sec)=>{
                  if(sec){
                    let countrySections = {}
                    countrySections['names']=[]
                    countrySections['details']={}
                    sec.forEach(
                      (section)=>{
                        const sectionId = section.payload.doc['id']
                        if (countryId === 'Belgium'){
                          // console.log('GSection: |' + sectionId + '|')
                        }
                        
                        
                        countrySections['names'].push(sectionId.toUpperCase())
                        // GET SECTION FIELDS
                        this.firestore.collection('countries/' + countryId + '/sections/' + sectionId + '/fields').valueChanges()
                        .subscribe(
                          (fields)=>{
                            // // console.log('Got Fields ', fields)
                            countrySections['details'][sectionId] = fields.sort(function (a, b) {
                              // // console.log('Sorting', a)
                              if (a['position'] < b['position']) {
                                  return -1;
                              }
                              if (b['position'] > a['position']) {
                                  return 1;
                              }
                              return 0;
                            })
                            countrySections['details'][sectionId]  = this.formatContent( countrySections['details'][sectionId] )
                          }
                        )
                      }
                    )
                    country['sections'] = countrySections
                  }
                }
            )
            this.countryNames.push(countryId)
            this.countries[countryId] = country
          }
        }
        )
      }
    )
            // this.getCountrySections(countryId)
            // .subscribe(
            //   (r)=>{
            //     if(r){
            //       // console.log('Got Section ' + typeof(r), r['sections'])
            //     }
            //     // // console.log('Got Section ' + typeof(r), r)
            //     // country.sections = r.length
            //     // country['fields'] = []
            //     // for (let index = 0; index < r.length; index++) {
            //     //   // console.log('Section ', r[index])
                  
            //     // }
            //     // r.forEach(
            //     //   (sec)=>{
            //     //     // console.log('Section ', sec)
            //     //     country['fields'].push( this.getSectionFields(countryId, sec) )
            //     //   }
            //     // )
    
                // this.countries.push( country)
      //         }
      //       )
            
      //     }
      //   )
      // }
    // )
    
    // console.log('Countries ', this.countries)
  }
  checkIfLastCharacterIsWrong(myString){
    let newString = myString
    if( myString.substring( myString.length - 1 , myString.length) === ' ' ){
      newString = myString.substring(0, myString.length - 1)
      // console.log(myString + ' needs to be corrected into ' + newString)
      
    }
    else{
      // console.log('Correct: ' + myString + ' Last character is ' + myString.substring( myString.length - 1 , myString.length) )
    }
    return newString
  }

  getCountrySections(countryId){
    return this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
      .subscribe()
  }

  formatContent(fields){
    fields.forEach(field => {
        if(field.content && field.type !== 'link'){
          let text = field.content;
          text = text.toString().replace(/	/g, '<br> • ')
          text = this.createTextLinks_(text)
          field.content = text 
        }
    });
    return fields
  }

 createTextLinks_(text) {
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function(match, space, url){
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
    }
  );
};

  // getCountrySections(countryId){
  //   let promise = new Promise((resolve, reject) => {
  //     let temp = []
  //     this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
  //     .subscribe(
  //       (res)=>{
  //         res.forEach(
  //           r=>{
  //             temp.push({'id': r.payload.doc['id']})
  //           }
  //         )
  //       }
  //     )
  //     resolve({'sections': temp})
  //   });
  //   return promise;

    
  // }
  
  getSectionFields(countryId, sectionId){
    let res = {country: 'countryId', section: 'sectionId'}

    return res
  }

  clickCountry(country){
    // this.selectedCountries[country] =  true;
    if(this.selectedCountries.includes(country)){
      // // console.log('[ClickCountry] Includes ' + country)
      for( var i = 0; i < this.selectedCountries.length; i++){ 
        if ( this.selectedCountries[i] === country) { this.selectedCountries.splice(i, 1); }
      }
    }
    else{
     
      this.selectedCountries.push(country)
    }
    // console.log('[ClickCountry]  ' + country + ' ' + this.selectedCountries )
    
  }

  clickSection(section){
    if(this.selectedSections.includes(section)){
      this.selectedSubSections[section] = []
      for( var i = 0; i < this.selectedSections.length; i++){ 
        if ( this.selectedSections[i] === section) { this.selectedSections.splice(i, 1); }
      }
    }
    else{
      this.selectedSections.push(section)
      this.selectAllSubsections(section)
    }
  }

  selectAllSubsections(section){
    section = section.toUpperCase();

    this.selectedSubSections[section] ={}

    // console.log('subcategs 2', this.countries['BULGARIA']['sections'])
    // console.log('SECTION NAME', section)
    // // console.log('subcategs 2', this.countries['Austria']['sections']['details'][section])
    
    if(this.countries['BULGARIA']['sections']['details'][section]){
      this.countries['BULGARIA']['sections']['details'][section].forEach((subsection) => {
        if(!subsection.no){
          // console.log('asta NU  e count: ', subsection)
          this.selectedSubSections[section][subsection.name] = { selected: true}
        }
        else{
          // console.log('Object ignored', subsection)
        }
      })
    }

    // console.log('selectAllSubsections ', this.selectedSubSections)
  }

  getAppendices(){
    this.firestore.collection('appendices/' ).valueChanges()
      .subscribe(
        (res)=>{
          this.appendices = res.sort((a, b) => {
            if (a['order'] > b['order']) {
              return 1;
          }
          if (b['order'] > a['order']) {
              return -1;
          }
          return 0;
          })
        }
      )
  }

  selectSection(sectionId){
    // this.canSaveFields = false
    // this.firestore.collection('countries/' + this.selectedCountry + '/sections/' + sectionId + '/fields').snapshotChanges()
    // .subscribe(
    //   (res)=>{
    //     res.sort(function (a, b) {
    //       if (a.payload.doc.data()['position'] > b.payload.doc.data()['position']) {
    //           return 1;
    //       }
    //       if (b.payload.doc.data()['position'] > a.payload.doc.data()['position']) {
    //           return -1;
    //       }
    //       return 0;
    //     })

    //     this.fields = []
    //     res.forEach(
    //       (r)=>{
    //         if(r.payload.doc['id'] === '_count'){
    //           this.fieldCounter = r.payload.doc.data()['no']
    //         }
    //         else{
    //           this.fields.push({key:r.payload.doc['id'], data: r.payload.doc.data() })
    //         }
    //       }
    //     )
    //   }
    // )
  }



  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

  slidePrev(slides) {
    slides.slidePrev();
  }
  slideNext(slides) {
    slides.slideNext();
  }



}
