import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.page.html',
  styleUrls: ['./confirm-message.page.scss'],
})
export class ConfirmMessagePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
