import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';  
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  constructor(private http:HttpClient, private firestore: AngularFirestore) { }  
  public getIPAddress()  
  {  
    return this.http.get('http://api.ipify.org/?format=json');  
  } 

  public saveToUserLog(email, userId, ip){
    const dataCurenta = new Date()
    const accessData = {
      'date_time': dataCurenta,
      'ip': ip
    }
    // console.log ('[IP SERVICE] Data to be saved ', accessData)
    this.firestore.collection('logs').doc(userId).set({'email': email})
    this.firestore.collection('logs').doc(userId).collection('accesslogs').add(accessData)
    .then(
      (res) => { // console.log ('[IP SERVICE] Successfuly saved access data ', res)
      }
    )
    .catch(
      (err) => { console.log ('[IP SERVICE] ERROR saving access data ', err)}
    )
  }

}
